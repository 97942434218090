import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {LoaderService} from './loader.service'
@Injectable({
  providedIn: 'root'
})
export class HttpBaseService {
 
  constructor(private http: HttpClient,private loaderService:LoaderService ) { }

  apiUrl = environment.apiUrl;

  post(url: string, data?: any, options: any = null): Observable<any> {
    if(options){
      return this.query('post', url, data, options);
    }
    return this.query('post', url, data);
  }

  get(url: string, options?: any): Observable<any> {
    return this.query('get', url, null, options);
  }

  patch(url: string, data?: any, requestOptions?: any): Observable<any> {
    return this.query('patch', url, data, requestOptions);
  }

  put(url: string, data?: any, requestOptions?: any): Observable<any> {
    return this.query('put', url, data, requestOptions);
  }

  delete(url: string, data?: any): Observable<any> {
    return this.query('delete', url, data);
  }
  
  query(method: string, url: string, data?: any, requestOptions?: any): Observable<any> {
    if(url !== 'check'){
      this.loaderService.startLoading();
    }
    const options = {
      ...requestOptions,
      body: data || {}
    };

    return this.http.request(method, `${this.apiUrl}${url}`, options).pipe(
      tap((response) => {
        this.loaderService.stopLoading();
        return response;

      }),
      catchError((err) => {
        this.loaderService.stopLoading();
        return throwError(() => err); 
      })
    );  
  }

}
